<template>
    <UDropdown :items="localeOptions" :popper="{ placement: 'bottom-end' }" :ui="{ width: 'w-fit min-w-24' }" square>
        <UButton square icon="i-heroicons-language" color="gray" variant="soft" size="xs" />
    </UDropdown>
</template>

<script setup lang="ts">
import { GlobalModalUpdateLocale } from '#components'

const authStore = useAuthStore();
const { userStateReady, isPartner, isClient } = storeToRefs(authStore);
const indexStore = useIndexStore();
const navigationStore = useNavigationStore();
const route = useRoute();
const modal = useModal()

const { t, locale } = useI18n()

onMounted(() => {
    if (process.client) {
        locale.value = localStorage.getItem('locale') || 'en'
    }
})

const localCookie = useCookie('locale')

function openLocaleModal() {
    console.log('openLocaleModal')
    modal.open(GlobalModalUpdateLocale)
}

const localeOptions = [
    [
        {
            label: 'English',
            click: () => {
                locale.value = "en"
                localStorage.setItem('locale', "en")
            }
        },
        {
            label: 'Español',
            click: () => {
                locale.value = "es"
                localStorage.setItem('locale', "es")
            }
        }
    ],
]
</script>