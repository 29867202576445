import { defineStore } from "pinia";

const state = () => ({
  themeColor: "blue",
});

const getters = {
  isAuthenticated(state: any): Boolean {
    return state.authUser ? true : false;
  },
};

export const useIndexStore = defineStore("indexStore", {
  state,
  getters,
});
